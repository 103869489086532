/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { Button, Go, LineSwipe } from "~components";

const TextGridButtonSection = ({
  data: {
    backgroundColor,
    fontColor,
    headingFontColor,
    heading,
    body,
    content,
    button,
    subheading
  }
}) => {
  return (
    <div
      id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          background: ${backgroundColor?.hex || `white`};
        `,
        tw`relative pt-20 md:pt-20 lg:pt-24 pb-20 md:pb-0`
      ]}
    >
      <Grid>
        <header
          css={[
            css`
              color: ${headingFontColor?.hex || `black`};
            `,
            tw`col-span-12 relative flex items-center pb-12 md:pb-0`
          ]}
        >
          <T.Head font="4">{subheading}</T.Head>

          <div css={[tw`w-32 relative block ml-5`]}>
            <LineSwipe
              color={headingFontColor?.hex === `#ffffff` && `#ffffff`}
              delay={500}
              duration={2000}
            />
          </div>
        </header>

        <div
          css={[
            css`
              color: ${headingFontColor?.hex || `black`};
            `,
            tw`col-span-12 md:col-span-6 relative md:mt-12 lg:mt-20 md:mb-24 lg:mb-32 pb-12 md:pb-0 md:pr-24 font-bold`
          ]}
        >
          <T.Head font="2">{heading}</T.Head>
        </div>

        <div
          css={[
            css`
              color: ${fontColor?.hex || `black`};
            `,
            tw`col-span-12 md:col-span-6 relative md:mt-12 lg:mt-20 md:mb-24 lg:mb-32`
          ]}
        >
          <T.Body font="2">{body}</T.Body>

          {button?.text && button?.url && (
            <Go to={button.url}>
              <Button
                _css={[tw`w-full md:w-72 h-16 mt-10 relative block border`]}
                text={button.text}
              />
            </Go>
          )}
        </div>

        {content.map((text, i) => {
          return (
            <div
              css={[
                css`
                  white-space: pre-wrap;
                  border-top: 1px solid black;
                `,
                tw`col-span-12 md:col-span-6 relative mt-8 md:mt-12 md:mb-20`,
                i % 2 === 0 ? tw`md:mr-10` : tw`md:ml-10`
              ]}
            >
              <T.Head _css={[tw`mt-6 font-bold`]} font="3">
                {text.heading}
              </T.Head>

              <T.Body _css={[tw`mt-6 md:mt-8 whitespace-pre-wrap`]} font="1">
                {text.body}
              </T.Body>
            </div>
          );
        })}
      </Grid>
    </div>
  );
};

export default TextGridButtonSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment TextGridButtonSectionFragment on SanityTextGridButtonSection {
    _type

    backgroundColor {
      hex
      title
    }
    headingFontColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    subheading
    heading
    body

    content {
      heading
      body
    }

    button {
      text
      url
    }
  }
`;
