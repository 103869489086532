/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "twin.macro";
import * as S from "~sanity/sections";
import { capitalizeString } from "~utils/helpers";

/** ============================================================================
 * @component
 * Core manifest of available sections inherited from Sanity.
 */
const PageBuilder = ({ pagebuilder }) => {
  const { sections } = pagebuilder;

  if (!sections?.[0]) {
    return <></>;
  }

  const jsx = [];

  sections.forEach((section, sectionIndex) => {
    const sectionKey = `pagebuilder-section-${sectionIndex}`;
    const Section = S?.[capitalizeString(section?._type)];

    if (!Section) {
      jsx.push(<React.Fragment key={sectionKey} />);
    }

    jsx.push(
      <section
        key={sectionKey}
        css={[
          css`
            background-color: ${section?.backgroundColor?.hex || `white`};
            color: ${section?.fontColor?.hex || `black`};
          `
        ]}
      >
        <Section data={section} />
      </section>
    );
  });

  return jsx;
};

export default PageBuilder;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment PageBuilderFragment on SanityPage {
    pagebuilder {
      sections {
        ...ArticleCarouselSectionFragment
        ...ArticleCTASectionFragment
        ...ArticleGridSectionFragment
        ...ArticleMasonrySectionFragment
        ...BioGridSectionFragment
        ...Column3SectionFragment
        ...ColumnButtonSectionFragment
        ...ColumnTextSectionFragment
        ...CoreServicesSectionFragment
        ...ExploreMoreSectionFragment
        ...FaqSectionFragment
        ...GetStartedSectionFragment
        ...ImageArticleSectionFragment
        ...ImageSectionFragment
        ...IntroductionSectionFragment
        ...LinkCarouselSectionFragment
        ...NewsCTASectionFragment
        ...NewsMasonrySectionFragment
        ...PatientFormSectionFragment
        ...RelevantArticlesSectionFragment
        ...ReferralFormSectionFragment
        ...ResourceFormSectionFragment
        ...StepsSectionFragment
        ...TableTextSectionFragment
        ...TabsSectionFragment
        ...TextCardSectionFragment
        ...TextGridButtonSectionFragment
        ...TextSectionFragment
        ...VideoSectionFragment
      }
    }
  }
`;
