/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import { EASING_CUBIC } from "~styles/Animations.jsx";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { Go, LineSwipe } from "~components";
import { getColor } from "~utils/helpers";

import { ReactComponent as ArrowRight } from "~assets/svg/arrow-right.svg";

const ExploreMoreSection = ({
  data: { backgroundColor, fontColor, heading, button1, button2 }
}) => {
  const buttons = [button1, button2];
  return (
    <div
      id={heading?.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          background: ${backgroundColor?.hex || getColor(`blue-faint`)};
          color: ${fontColor?.hex || getColor(`blue-deep`)};
        `,
        tw`w-full relative pt-20 md:pt-20 pb-10 md:pb-20`
      ]}
    >
      <Grid>
        <header
          css={[
            css`
              margin-top: -3px;
            `,
            tw`col-span-12 md:col-span-4 relative flex items-start whitespace-nowrap`
          ]}
        >
          <div css={[tw`relative`]}>
            <T.Head
              _css={[
                css`
                  transform: translateY(-11px);
                `,
                tw``
              ]}
              font="4"
            >
              {heading}
            </T.Head>
          </div>

          <div css={[tw`w-32 relative md:mt-1 mb-16 md:mb-0 ml-6`]}>
            <LineSwipe color="#032960a0" delay={500} duration={2000} />
          </div>
        </header>

        {buttons?.[0] &&
          buttons.map(({ text, url }, buttonIndex) => (
            <article
              key={`exploremore-section-link-${text}`}
              css={[
                css``,
                buttonIndex === 0
                  ? tw`col-span-12 md:col-span-4 md:col-start-5 relative md:px-3`
                  : tw`col-span-12 md:col-span-4 relative md:px-3`
              ]}
            >
              <Go
                to={url}
                _css={[
                  css`
                    p,
                    svg {
                      transition: transform 0.3s ${EASING_CUBIC};
                    }

                    &:hover {
                      background: rgba(255, 255, 255, 0.4);

                      p {
                        transform: translate3d(0.75rem, 0, 0);
                      }
                      svg {
                        transform: translate3d(-0.75rem, 0, 0);
                      }
                    }
                  `,
                  tw`w-full h-full relative block mb-6 md:mb-0 pt-4 md:pt-5 pb-4 md:pb-5 flex items-start md:items-center justify-between border-t border-black text-black`
                ]}
              >
                <T.Body _css={[tw`font-bold`]} font="2">
                  {text}
                </T.Body>

                <ArrowRight css={[tw`w-6`]} fill={getColor(`blue-deep`)} />
              </Go>
            </article>
          ))}
      </Grid>
    </div>
  );
};

export default ExploreMoreSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment ExploreMoreSectionFragment on SanityExploreMoreSection {
    _type

    heading

    button1 {
      text
      url
    }

    button2 {
      text
      url
    }
  }
`;
