/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { LineSwipe } from "~components";

const ColumnTextSection = ({
  data: {
    backgroundColor,
    headingColor,
    fontColor,
    subheading,
    heading,
    leftBody,
    rightBody,
    alignment
  }
}) => {
  return (
    <div
      id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          background: ${backgroundColor?.hex};
        `,
        tw`relative pt-20 md:pt-20 lg:pt-24 md:pb-20 lg:pb-24`
      ]}
    >
      <Grid _css={[tw`z-10`]}>
        <header
          css={[
            tw`col-span-12 relative flex items-center mb-12 md:mb-12 lg:mb-16`
          ]}
        >
          <T.Head
            _css={[
              css`
                color: ${headingColor?.hex || `#000000`};
              `
            ]}
            font="4"
          >
            {subheading}
          </T.Head>

          <div css={[tw`w-32 relative block ml-5`]}>
            <LineSwipe color="#84bbd9" delay={500} duration={2000} />
          </div>
        </header>

        <div
          css={[
            tw`col-span-12 md:col-span-5 relative mb-12 md:mb-16`,
            alignment === `center` && tw`col-span-12 md:col-span-6`,
            leftBody?.length > 0 &&
              alignment === `center` &&
              tw`col-span-12 md:col-span-7`,
            !(leftBody?.length > 0) && alignment !== `center` && tw`md:pr-24`
          ]}
        >
          <T.Head
            _css={[
              css`
                white-space: pre-wrap;
                color: ${headingColor?.hex || `#000000`};
              `,
              tw`md:pr-8 font-bold`
            ]}
            font="2"
          >
            {heading}
          </T.Head>
        </div>

        {leftBody?.length > 0 && (
          <div
            css={[
              tw`col-span-12 md:col-span-5 md:col-start-1 relative mb-20 md:pr-24`,
              alignment === `center` && tw`col-span-12 md:col-span-6 md:pr-36`
            ]}
          >
            <T.Body
              _css={[
                css`
                  white-space: pre-wrap;
                  color: ${fontColor?.hex || `#000000`};
                `
              ]}
              font="1"
            >
              {leftBody}
            </T.Body>
          </div>
        )}

        <div
          css={[
            tw`col-span-12 md:col-span-5 md:col-start-6 relative mb-20 md:pr-24`,
            alignment === `center` &&
              tw`col-span-12 md:col-span-6 md:col-start-7 md:pr-36`
          ]}
        >
          <T.Body
            _css={[
              css`
                white-space: pre-wrap;
                color: ${fontColor?.hex || `#000000`};
              `
            ]}
            font="1"
          >
            {rightBody}
          </T.Body>
        </div>
      </Grid>
    </div>
  );
};

export default ColumnTextSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment ColumnTextSectionFragment on SanityColumnTextSection {
    _type

    backgroundColor {
      hex
      title
    }
    headingColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    subheading
    heading
    leftBody
    rightBody
    alignment
  }
`;
