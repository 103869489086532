/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { BioCard, LineSwipe } from "~components";

const BioGridSection = ({
  data: { subheading, heading, bios, headingFontColor }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  return (
    <div
      id={`${subheading?.replace(/\s+/g, `-`).toLowerCase()}`}
      css={[
        css`
          //
        `,
        tw`w-full relative pt-24 pb-24 flex flex-col items-center justify-center`
      ]}
    >
      <Grid>
        <header
          css={[
            css`
              color: ${headingFontColor?.hex || `#000000`};
            `,
            tw`col-span-12 relative flex items-center`
          ]}
        >
          <T.Head font="4">{subheading}</T.Head>

          <div css={[tw`w-32 relative block ml-5`]}>
            <LineSwipe color="#84bbd9" delay={500} duration={2000} />
          </div>
        </header>

        <div
          css={[
            css`
              color: ${headingFontColor?.hex || `#000000`};
            `,
            tw`col-span-12 md:col-span-5 relative mt-16 mb-20 font-bold`
          ]}
        >
          <T.Head font="3">{heading}</T.Head>
        </div>

        {bios?.[0] && (
          <div css={[tw`col-span-12 relative`]}>
            <ul css={[tw`grid md:grid-cols-3 lg:grid-cols-4 gap-4`]}>
              {bios.map((bioEntry) => (
                <li key={`bio-card-${bioEntry.name}-${bioEntry.surname}`}>
                  <BioCard
                    data={{
                      bio: bioEntry
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default BioGridSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment BioGridSectionFragment on SanityBioGridSection {
    _type

    headingFontColor {
      hex
      title
    }

    subheading
    heading

    bios {
      title
      name
      surname
      header
      body
      qualifications
      specialisation

      image {
        asset {
          gatsbyImageData(width: 1200, placeholder: NONE)
        }
      }
    }
  }
`;
