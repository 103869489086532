/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import { Image } from "~components";

const ImageSection = ({ data: { image } }) => {
  const containerRef = useRef();
  const [containerHeight, setContainerHeight] = useState(
    image.asset.gatsbyImageData.height
  );
  const aspectRatio =
    image.asset.gatsbyImageData.height / image.asset.gatsbyImageData.width;

  useEffect(() => {
    function handleResize() {
      setContainerHeight(aspectRatio * containerRef.current.offsetWidth);
    }
    window.addEventListener(`resize`, handleResize);
    handleResize();
    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  return (
    <div
      ref={containerRef}
      css={[
        css`
          width: ${`100%`};
          height: ${containerHeight}px;
        `,
        tw`relative`
      ]}
    >
      <Image
        _css={[
          css`
            //
          `,
          tw`w-full h-full`
        ]}
        image={image}
      />
    </div>
  );
};

export default ImageSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment ImageSectionFragment on SanityImageSection {
    _type

    image {
      asset {
        gatsbyImageData(width: 1200, placeholder: NONE)
      }
    }
  }
`;
