/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { LineSwipe } from "~components";

const ArticleGridSection = ({ data: { subheading, heading } }) => {
  return (
    <div
      id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          //
        `,
        tw``
      ]}
    >
      <Grid>
        <header css={[tw`col-span-12 relative flex items-center`]}>
          <T.Head font="4">{subheading}</T.Head>

          <div css={[tw`w-32 relative block ml-5`]}>
            <LineSwipe color="#84bbd9" delay={500} duration={2000} />
          </div>
        </header>

        <div css={[tw`col-span-4 relative mt-20 mb-20`]}>
          <T.Head font="3">{heading}</T.Head>
        </div>
      </Grid>
    </div>
  );
};

export default ArticleGridSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment ArticleGridSectionFragment on SanityArticleGridSection {
    _type

    subheading
    heading
  }
`;
