/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { Button, Go, Image, LineSwipe } from "~components";

import { ReactComponent as Wave1ABlue } from "~assets/svg/header-1-shape-a-blue.svg";

const IMAGE_OFFSET = `4rem`;

const GetStartedSection = ({
  data: { backgroundColor, subheading, heading, image, buttonText, buttonUrl }
}) => (
  <div
    id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
    css={[
      css`
        overflow: visible;
        background: ${backgroundColor?.hex};
      `,
      tw`w-full relative pt-20 md:pt-0`
    ]}
  >
    <div
      css={[
        css`
          height: 100%;
          overflow: hidden;
        `,
        tw`w-full absolute right-0 bottom-0 left-0 z-10 flex flex-nowrap items-end justify-center bg-blue-deep`
      ]}
    >
      <Wave1ABlue />
    </div>

    <div
      css={[
        css`
          //
        `,
        tw`relative z-20 text-white`
      ]}
    >
      <Grid _css={[tw`z-10`]}>
        <header
          css={[
            tw`col-span-full md:col-span-7 relative flex items-center md:pt-24 lg:pt-32`
          ]}
        >
          <T.Head font="4">{subheading}</T.Head>

          <div css={[tw`w-32 relative block ml-5`]}>
            <LineSwipe color="#3876AE" delay={500} duration={2000} />
          </div>
        </header>

        <div
          css={[
            tw`col-span-full md:col-span-7 relative md:pr-8 mt-10 md:mt-16 pb-20 md:pb-24 lg:pb-32 font-bold`
          ]}
        >
          <T.Head font="2">{heading}</T.Head>

          <Go to={buttonUrl}>
            <Button
              _css={[tw`w-64 h-16 relative block mt-10 md:mt-16`]}
              text={buttonText}
            />
          </Go>
        </div>
      </Grid>

      {image && (
        <div
          css={[
            css`
              //
            `,
            tw`w-1/2 h-full absolute bottom-0 right-0 hidden md:flex justify-end pointer-events-none`
          ]}
        >
          <Image
            _css={[
              css`
                max-width: none;
                max-height: none;
                height: calc(100% + ${IMAGE_OFFSET});
                top: -${IMAGE_OFFSET};

                img {
                  width: auto !important;
                  object-fit: inherit !important;
                }
              `,
              tw`relative block`
            ]}
            image={image}
          />
        </div>
      )}
    </div>
  </div>
);

export default GetStartedSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment GetStartedSectionFragment on SanityGetStartedSection {
    _type

    backgroundColor {
      hex
      title
    }

    subheading
    heading
    buttonText
    buttonUrl

    image {
      asset {
        gatsbyImageData(width: 1200, placeholder: NONE)
      }
    }
  }
`;
