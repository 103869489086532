/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";
/* eslint-disable arrow-body-style */

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";

const Column3Section = ({
  data: { backgroundColor, fontColor, columnLeft, columnMiddle, columnRight }
}) => {
  return (
    <div
      css={[
        css`
          background: ${backgroundColor?.hex};
          color: ${fontColor?.hex};
        `,
        tw`relative block md:pt-20 lg:pt-24 md:pb-20 lg:pb-24`
      ]}
    >
      <Grid>
        <div
          css={[
            css`
              @media screen and (min-width: 1025px) {
                border-right: 1px solid;
              }
            `,
            tw`col-span-12 md:col-span-4 relative pt-16 md:pt-0 md:pr-12 lg:pr-24`
          ]}
        >
          <T.Head _css={[tw`font-bold`]} font="3">
            {columnLeft.heading}
          </T.Head>

          <T.Body
            font="1"
            _css={[
              tw`mt-8`,
              css`
                white-space: pre-wrap;
              `
            ]}
          >
            {columnLeft.body}
          </T.Body>
        </div>

        <div
          css={[
            css`
              border-top: 1px solid;

              @media screen and (min-width: 1025px) {
                border-top: none;
                border-right: 1px solid;
              }
            `,
            tw`col-span-12 md:col-span-4 relative mt-6 md:mt-0 pt-12 md:pt-0 md:pr-10 lg:pr-20 md:pl-8 lg:pl-16`
          ]}
        >
          <T.Head _css={[tw`font-bold`]} font="3">
            {columnMiddle.heading}
          </T.Head>

          <T.Body
            font="1"
            _css={[
              tw`mt-8`,
              css`
                white-space: pre-wrap;
              `
            ]}
          >
            {columnMiddle.body}
          </T.Body>
        </div>

        <div
          css={[
            css`
              border-top: 1px solid;

              @media screen and (min-width: 1025px) {
                border-top: none;
              }
            `,
            tw`col-span-12 md:col-span-4 relative mt-6 md:mt-0 mr-1 pt-12 md:pt-0 pb-20 md:pb-0 md:pl-8 lg:pl-16 md:pr-8 lg:pr-16`
          ]}
        >
          <T.Head _css={[tw`font-bold`]} font="3">
            {columnRight.heading}
          </T.Head>

          <T.Body
            font="1"
            _css={[
              tw`mt-8`,
              css`
                white-space: pre-wrap;
              `
            ]}
          >
            {columnRight.body}
          </T.Body>
        </div>
      </Grid>
    </div>
  );
};

export default Column3Section;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment Column3SectionFragment on SanityColumn3Section {
    _type

    backgroundColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    columnLeft {
      heading
      body
    }

    columnMiddle {
      heading
      body
    }

    columnRight {
      heading
      body
    }
  }
`;
