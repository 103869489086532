/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { ArticleCard, Button, Go, LineSwipe } from "~components";

const NewsCTASection = ({
  data: { subheading, heading, articles, button }
}) => (
  <div
    id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
    css={[
      css`
        //
      `,
      tw`w-full relative pt-16 lg:pt-32 pb-10 md:pb-16 lg:pb-24 flex flex-col items-center justify-center`
    ]}
  >
    <Grid>
      <header
        css={[tw`col-span-12 relative flex items-center md:mb-16 lg:mb-20`]}
      >
        <T.Head _css={[tw`text-blue-med`]} font="4">
          {subheading}
        </T.Head>

        <div css={[tw`w-32 relative block ml-5`]} color="text-blue-med">
          <LineSwipe color="#84bbd9" delay={500} duration={2000} />
        </div>
      </header>

      <div
        css={[
          tw`col-span-full md:col-span-7 relative mt-8 md:mt-0 mb-12 md:mb-24`
        ]}
      >
        <T.Head _css={[tw`font-bold text-blue-med`]} font="2">
          {heading}
        </T.Head>
      </div>
    </Grid>

    <Grid>
      {articles?.[0] &&
        articles.map((article) => (
          <div
            key={article.id}
            css={[tw`col-span-full md:col-span-4 relative block mb-5 md:mb-0`]}
          >
            <ArticleCard data={article} />
          </div>
        ))}

      {button?.text && button?.url && (
        <Go
          to={button.url}
          _css={[tw`col-span-full relative flex md:justify-end mt-4 md:mt-16`]}
        >
          <Button
            _css={[tw`w-56 h-16 relative block border`]}
            text="View all"
          />
        </Go>
      )}
    </Grid>
  </div>
);

export default NewsCTASection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment NewsCTASectionFragment on SanityNewsCTASection {
    _type

    subheading
    heading

    button {
      text
      url
    }

    articles {
      ... on SanityEvent {
        _type

        slug {
          current
        }

        id
        title
        excerpt
        tag {
          title
          hex
        }
      }

      ... on SanityNews {
        _type

        slug {
          current
        }

        id
        title
        excerpt
        tag {
          title
          hex
        }
      }
    }
  }
`;
