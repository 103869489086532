/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";

const RelevantArticlesSection = ({ data: { heading, body } }) => (
  <div
    id={heading?.replace(/\s+/g, `-`).toLowerCase()}
    css={[
      css`
        // height: 56.25vw;
      `,
      tw`w-full relative flex flex-col items-center justify-center`
    ]}
  >
    <T.Head font="1">{heading}</T.Head>
    <T.Body font="1">{body}</T.Body>
  </div>
);

export default RelevantArticlesSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment RelevantArticlesSectionFragment on SanityRelevantArticlesSection {
    _type

    backgroundColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }
  }
`;
