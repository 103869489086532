/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React, { useRef } from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { useDevice } from "~hooks";
import { InfoCard, LineSwipe, WaveCanvas } from "~components";

const ResourceFormSection = ({
  data: {
    backgroundColor,
    headingFontColor,
    fontColor,
    formstack,
    formstackHeight,
    formstackHeightXS,
    heading,
    body,
    cards
  }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { isDesktop } = useDevice();

  const formRef = useRef();

  // ---------------------------------------------------------------------------
  // lifecycle

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      id={heading?.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          background-color: ${backgroundColor?.hex || `#f5f6fa`};

          iframe {
            width: 100% !important;
          }
        `,
        tw`w-full relative flex flex-col items-center justify-center text-blue-deep  pt-48 lg:pt-72 md:pt-64 md:pb-20 lg:pb-24`
      ]}
    >
      <div
        css={[
          tw`w-screen h-screen fixed top-0 right-0 bottom-0 left-0 pointer-events-none`
        ]}
      >
        <WaveCanvas />
      </div>

      <Grid _css={[tw`z-20`]}>
        <header
          css={[
            css`
              color: ${headingFontColor?.hex || `#002762`};
            `,
            tw`relative flex items-center pb-16 col-span-12`
          ]}
        >
          <T.Head font="4">{heading}</T.Head>

          <div css={[tw`w-32 relative block ml-5`]}>
            <LineSwipe
              color={headingFontColor?.hex === `#ffffff` && `#ffffff`}
              delay={500}
              duration={2000}
            />
          </div>
        </header>

        <div css={[tw`relative col-span-4`]}>
          <div css={[css``, tw`relative md:pr-8 lg:pr-0 pb-16 lg:pb-20`]}>
            <T.Body
              _css={[
                css`
                  color: ${fontColor?.hex || `#0008`};
                `,
                tw`whitespace-pre-wrap`
              ]}
              font="1"
            >
              {body}
            </T.Body>
          </div>

          {/* Cards section */}
          <div css={[tw``]}>
            <div css={[css``, tw`flex flex-col space-y-8`]}>
              {cards.map((card) => {
                return (
                  <div css={[tw`md:w-full lg:w-3/4`]}>
                    <InfoCard
                      backgroundColor={card.backgroundColor}
                      headingFontColor={card.headingFontColor}
                      fontColor={card.fontColor}
                      heading={card.heading}
                      subsections={card.subsections}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div css={[tw`col-span-12 md:col-span-8 relative`]}>
          <iframe
            ref={formRef}
            css={[
              css`
                width: 100%;
                position: relative;
                display: block;
                overflow-y: scroll;
                overflow-x: hidden;
              `
            ]}
            src={formstack}
            title="RHW Resource Form"
            height={isDesktop() ? formstackHeight : formstackHeightXS}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </Grid>
    </div>
  );
};

export default ResourceFormSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment ResourceFormSectionFragment on SanityResourceFormSection {
    _type

    backgroundColor {
      hex
      title
    }

    headingFontColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    heading
    body
    formstack
    formstackHeight
    formstackHeightXS

    cards {
      backgroundColor {
        hex
        title
      }

      headingFontColor {
        hex
        title
      }

      fontColor {
        hex
        title
      }

      heading

      subsections {
        _type
        body
        heading
      }
    }
  }
`;
