/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { ArticleGrid, LineSwipe } from "~components";

const NewsMasonrySection = ({
  data: { fontColor, heading, body, articles }
}) => {
  let i;
  const filteredArticles = articles?.filter(
    (article) => article.published === true
  );
  filteredArticles.sort((a, b) => {
    return b.date?.replace(/-/g, ``) - a.date?.replace(/-/g, ``);
  });

  // ===========================================================================
  // render

  return (
    <div
      id={`news-masonry-${heading?.replace(/\s+/g, `-`).toLowerCase()}`}
      css={[
        css`
          //
        `,
        tw`relative pt-24 md:pb-16 pb-20`
      ]}
    >
      <header css={[tw`relative`]}>
        <Grid>
          <div
            css={[
              tw`col-span-12 md:col-span-4 relative flex items-start pr-12`
            ]}
          >
            <T.Head
              _css={[
                css`
                  transform: translateY(-0.9rem);
                  color: ${fontColor?.hex || `#000000`};
                `,
                tw``
              ]}
              font="4"
            >
              {heading}
            </T.Head>

            <div css={[tw`w-32 relative md:mt-1 mb-16 md:mb-0 ml-6`]}>
              <LineSwipe color="#84bbd9" delay={500} duration={2000} />
            </div>
          </div>

          <div
            css={[
              css`
                color: ${`#000000`};
              `,
              tw`col-span-12 md:col-span-7 mt-8 md:-mt-2 mb-16 md:mb-0`
            ]}
          >
            <T.Body font="2">{body}</T.Body>
          </div>
        </Grid>
      </header>

      <div css={[tw`md:pt-24`]}>
        <ArticleGrid articles={filteredArticles} />
      </div>
    </div>
  );
};

export default NewsMasonrySection;

/** ============================================================================
 * graphql
 */

export const query = graphql`
  fragment NewsMasonrySectionFragment on SanityNewsMasonrySection {
    _type

    heading
    body

    fontColor {
      hex
      title
    }

    articles {
      ... on SanityEvent {
        _type

        slug {
          current
        }
        published

        id
        title
        excerpt

        cardImage {
          asset {
            gatsbyImageData(width: 1024, placeholder: NONE)
          }
        }

        tag {
          title
          hex
        }

        date
      }
      ... on SanityNews {
        _type

        slug {
          current
        }
        published

        id
        title
        excerpt

        cardImage {
          asset {
            gatsbyImageData(width: 1024, placeholder: NONE)
          }
        }

        tag {
          title
          hex
        }

        date
      }
    }
  }
`;
