/* eslint-disable react/no-danger */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import { useWindowDimensions } from "~hooks";
import * as T from "~styles/Typography.jsx";
import Grid, { GRID_PADDING_PX } from "~styles/Grid.jsx";
import { Image, Button, Go, LineSwipe } from "~components";

/** ============================================================================
 * @component
 * Full-bleed image with article text.
 */
const ImageArticleSection = ({
  data: {
    backgroundColor,
    headingFontColor,
    fontColor,
    subheading,
    heading,
    headingPosition,
    body,
    subsections,
    image,
    imagePosition
  }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [containerCSS, setContainerCSS] = useState(null);

  const { screen, windowSize } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!windowSize?.width) {
      return;
    }

    let widthDiff = 0;

    if (windowSize.width > 1440) {
      widthDiff = parseInt((windowSize.width - 1440) / 2);
    }

    const width = `calc(
      100% + ${widthDiff}px +
        ${GRID_PADDING_PX}px
    );`;

    setContainerCSS(width);
  }, [windowSize]);

  // ---------------------------------------------------------------------------
  // variables

  const imageDiv = (
    <div
      css={[
        css`
          @media screen and (min-width: 1025px) {
            height: auto;
            align-self: stretch;
          }
        `,
        tw`col-span-12 md:col-span-6 relative`,
        headingPosition === `inline` ? tw`row-span-2` : tw`row-span-1`
      ]}
    >
      <div
        css={[
          css`
            width: calc(100% + 4rem);
            margin-left: -2rem;

            @media screen and (min-width: 1025px) {
              margin-left: 0rem;
              width: ${containerCSS || `100%`};
              max-width: none;
              height: 100%;
            }
          `,
          tw`md:absolute`,
          imagePosition === `right` ? tw`md:left-0` : tw`md:right-0`
        ]}
      >
        <Image _css={[tw`w-full h-full block object-cover`]} image={image} />
      </div>
    </div>
  );

  const headerDiv = (
    <header
      css={[
        css`
          color: ${headingFontColor?.hex || `#000000`};
        `,
        tw`relative flex items-center md:mt-20 lg:mt-24 mb-8 md:mb-12 lg:mb-16 whitespace-nowrap`,
        headingPosition === `above` ? tw`col-span-12` : tw`col-span-6`,
        imagePosition === `left` &&
          headingPosition !== `above` &&
          tw`md:ml-12 lg:ml-16`
      ]}
    >
      <T.Head font="4" level="2">
        {subheading}
      </T.Head>

      <div css={[tw`w-32 relative block ml-5`]}>
        <LineSwipe
          color={headingFontColor?.hex === `#ffffff` && `#ffffff`}
          delay={500}
          duration={2000}
        />
      </div>
    </header>
  );

  const bodyDiv = (
    <div
      css={[
        tw`col-span-12 md:col-span-6 relative mb-8 md:mb-16 pt-2 md:pb-20 lg:pb-24`,
        imagePosition === `left` ? tw`md:ml-16` : tw`md:mr-16`
      ]}
    >
      <T.Head
        _css={[
          css`
            color: ${headingFontColor?.hex || `#000000`};
          `,
          tw`mb-10 md:mb-0 font-bold`
        ]}
        font="2"
        level="3"
      >
        {heading}
      </T.Head>

      <T.Body
        _css={[
          css`
            white-space: pre-wrap;
            color: ${fontColor?.hex || `#000000`};
          `,
          tw`w-full md:w-4/5 relative md:mt-12 lg:mt-16`
        ]}
        font="1"
      >
        {body}
      </T.Body>

      {subsections?.map((subsection) => {
        if (subsection?._type === `headingBody`) {
          return (
            <>
              <T.Head
                _css={[
                  css`
                    color: ${headingFontColor?.hex || `#000000`};
                  `,
                  tw`font-bold mt-16`
                ]}
                font="3"
                level="4"
              >
                {subsection.heading}
              </T.Head>

              <T.Body
                _css={[
                  css`
                    color: ${fontColor?.hex || `#000000`};
                    white-space: pre-wrap;
                  `,
                  tw`w-4/5 relative mt-4`
                ]}
                font="1"
              >
                {subsection.body}
              </T.Body>
            </>
          );
        }

        if (subsection?._type === `buttonLink`) {
          return (
            <Go to={subsection.url} _css={[tw`relative flex mt-10 md:mt-16`]}>
              <Button
                _css={[tw`w-5/6 md:w-2/3 h-16 relative block border`]}
                text={subsection.text}
              />
            </Go>
          );
        }
        return <></>;
      })}
    </div>
  );

  let contentArray;

  if (screen === `xs` || screen === `sm`) {
    contentArray = [headerDiv, bodyDiv, imageDiv];
  } else if (headingPosition === `above` && imagePosition === `right`) {
    contentArray = [headerDiv, bodyDiv, imageDiv];
  } else if (headingPosition === `inline` && imagePosition === `left`) {
    contentArray = [imageDiv, headerDiv, bodyDiv];
  } else {
    contentArray = [headerDiv, imageDiv, bodyDiv];
  }

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          background: ${backgroundColor?.hex || `#ffffff`};
        `,
        tw`w-full relative md:flex md:flex-col md:items-center md:justify-center pt-20 md:pt-0 `
      ]}
    >
      <Grid>
        {contentArray.map((content) => {
          return content;
        })}
      </Grid>
    </div>
  );
};

export default ImageArticleSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment ImageArticleSectionFragment on SanityImageArticleSection {
    _type

    backgroundColor {
      hex
      title
    }
    headingFontColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    subheading
    heading
    headingPosition
    body

    subsections {
      ... on SanityButtonLink {
        _type
        text
        url
      }
      ... on SanityHeadingBody {
        _type
        body
        heading
      }
    }

    image {
      asset {
        gatsbyImageData(width: 1200, placeholder: NONE)
      }
    }
    imagePosition
  }
`;
