/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import * as T from "~styles/Typography.jsx";
import Grid from "~styles/Grid.jsx";
import { LineSwipe } from "~components";

const ArticleCarouselSection = ({
  data: { subheading, heading, articles, button }
}) => (
  <div
    id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
    css={[
      css`
        //
      `,
      tw`w-full relative pt-24 pb-24 flex flex-col items-center justify-center`
    ]}
  >
    <Grid>
      <header css={[tw`col-span-12 relative flex items-center`]}>
        <T.Head _css={[tw`text-blue-med`]} font="4">
          {subheading}
        </T.Head>

        <div css={[tw`w-32 relative block ml-5`]} color="text-blue-med">
          <LineSwipe color="#84bbd9" delay={500} duration={2000} />
        </div>
      </header>

      <div css={[tw`col-span-full md:col-span-6 relative mt-16 mb-24`]}>
        <T.Head _css={[tw`font-bold text-blue-med`]} font="3">
          {heading}
        </T.Head>
      </div>
    </Grid>
  </div>
);

export default ArticleCarouselSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment ArticleCarouselSectionFragment on SanityArticleCarouselSection {
    _type

    subheading
    heading

    articles {
      title
      excerpt
      tag {
        title
        hex
      }
    }
  }
`;
